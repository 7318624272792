import React from 'react';
import './ HorizontalCard.css'; 

const HorizontalCard = ({ className, children, borderColor, onClick, cursor }) => {
  return (
    <div onClick={() => {onClick && onClick()}} className={`horizontal__card${className ? ` ${className}` : ''}`} style={{ borderLeft: `3px solid ${borderColor}`, cursor: cursor &&'pointer'}}>
      {children}
    </div>
  );
};

export default  HorizontalCard;
