import { useState, useEffect, useRef, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import {
  faInfoCircle,
  faCircleInfo,
  faChevronCircleLeft,
  faChevronLeft,
  faChevronRight,
  faChevronCircleRight,
  faMagnifyingGlass,
  faCaretDown,
  faCaretUp,
  faArrowUpAZ,
  faArrowUpZA,
  faX,
  faTrash,
  faTimes
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch } from "react-redux";
import healthCheck from "../../assets/wallpaper1.png";
import PagerdutyServiceRequest from "../../assets/assets/categories/PagerdutyServiceRequest.svg";
// import CorePlatform from "../../assets/assets/categories/CorePlatform.svg";
import OpenSource from "../../assets/assets/categories/OpenSource.svg";
import  PDU from "../../assets/assets/categories/PDU.svg"
import ProfessionalServices from "../../assets/assets/categories/ProfessionalServices.svg";
import IncidentManagement from "../../assets/assets/categories/IncidentManagement.svg";
import OnRequestReport from "../../assets/assets/categories/OnRequestReport.svg";
import Solutions from "../../assets/assets/categories/SolutionsIcon.svg";
import SolutionAccelerator from "../../assets/assets/categories/SolutionAccelerator.svg";
import DevelopersRelations from "../../assets/assets/categories/developers_relations.svg";
import EnterpriseIncidentManagement from "../../assets/assets/categories/EnterpriseIncidentManagement.svg";
import Copyright from "../../components/Copyright"
import Pagination from "../../components/PaginationA";
import logo from "../../assets/PagerDuty-WhiteRGB_white.svg";
import pageyUser from "../../assets/pageyUser.png";
import { apiRoutes, apiRequest } from "../../services";
import { useSolutionProduct } from '../../query';
import { cartSolution, controlTour } from "../../services/subjects.js";
import SkeletonTop from "../../components/Skeletons/SolutionGallery/SolutionGallery";
import ContainerCard from '../../components/ContainerCard'
import TitleApp from '../../components/TitleApp'
import UxPagination from '../..//components/UxPagination/UxPagination.js';
import { ReactComponent as Time } from "../../assets/time.svg";
import { ReactComponent as Filter } from "../../assets/Filter.svg";
import Dialog from '../../components/Dialog/Dialog.tsx';
import { StyledHeaderContainer } from "../../components/Dialog/DialogStyled.tsx";
import { StyledInfoButton } from "../../components/Dialog/DialogStyled.tsx";
import useDialog  from '../../components/Dialog/useDialog.ts';
import  {dialogContent}  from '../../components/Dialog/DialogContent.tsx';
import './SolutionGallery.css'
import { setNavigationParams, useNavigationParamsSelector } from "@/store/states/index.ts";



export default function SolutionGallery() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const navigationParams = useNavigationParamsSelector();
  const [category, setCategory] = useState([]);
  const [solutionType, setSolutionType] = useState([]);
  const [classifications, setClassifications] = useState([]);
  const [operationCloudProducts, setOperationCloudProducts] = useState([]);
  const [operationCloudAreaProducts, setOperationCloudAreaProducts] = useState([]);
  const [solutionFeature, setSolutionFeature] = useState([]);
  const [onSearchCategory, setOnSearchCategory] = useState('');
  const [startIndex, setStartIndex] = useState(0);
  const [productByPage, setProductByPage] = useState(5)
  const [firstElement, setFirstElement] = useState(0);
  const [lastElement, setLastElement] = useState(5);
  const [showRefineResults, setShowRefineResults] = useState(true);
  const [modalOpenDetailSolution, setModalOpenDetailSolution] = useState(false);
  const [isOpenSolutionCategories, setIsOpenSolutionCategories] = useState(false);
  const [isOpenCategories, setIsOpenCategories] = useState(false);
  const [isOpenClassification, setIsOpenClassification] = useState(false);
  const [isOpenEstimatedHours, setIsOpenEstimatedHours] = useState(false);
  const [isOpenSolutionType, setIsOpenSolutionType] = useState(false);
  const [msgCart, setMsgCart] = useState(false);
  const [searchCategories, setSearchCategories] = useState('');
  const [searchClassifications, setSearchClassifications] = useState('');
  const [searchSolutionCategory, setSearchSolutionCategory] = useState('');
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedClassifications, setSelectedClassifications] = useState([]);
  const [selectedSolutionCategory, setSelectedSolutionCategory] = useState([]);
  const [selectedSolutionType, setSelectedSolutionType] = useState([]);
  const [productsInTheCart, setProductsInTheCart] = useState([]);
  const [searchSolutionType, setSearchSolutionType] = useState('');
  const [searchFeatureArea, setSearchEstimatedHours] = useState('');
  const [selectedFeatureArea, setSelectedEstimatedHours] = useState([]);
  const [selectProduct, setSelectProduct] = useState('');
  const [solutionHours, setSolutionHours] = useState({});
  const [loadingTop, setLoadingTop] = useState(false);
  const [loadingProducts, setLoadingProducts] = useState(false);
  const [LoadingSRA, setLoadingSRA] = useState(false);
  const [queryParams, setQueryParams] = useState({});
  const [detailProduct, setDetailProduct] = useState({});
  const [selectHours, setSelectHours] = useState('');
  const [sraNameSelected, setSraNameSelected] = useState('');
  const sliderRef = useRef(null);
  const dropdownRefClassifications = useRef(null);
  const dropdownRefSolutionCategories = useRef(null);
  const dropdownRefCategories = useRef(null);
  const dropdownRefSolutionType = useRef(null);
  const dropdownRefFeatureArea = useRef(null);
  const originalCardListRef = useRef([]);
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPage, setTotalPage] = useState(0)
  const [isOpenDropdown, setIsOpenDropdown] = useState(false);
  const itemsPerPage = 10;
  const { openDialog, closeDialog, isDialogOpen } = useDialog();

  useEffect(() => {
    const route = window.location.pathname
    const splitRoute = route.split("/");
    const nameSra = splitRoute[2];
    setSraNameSelected(nameSra)

    if (nameSra === undefined) {
  
    }
    getSolutionCategory()
    getOperationCloudProducts()
    getClassifications()
    getOperationCloudAreaProducts()
    getCart()
    getHours()
    getSolutionType()
  }, [])

  useEffect(() => {
    if(navigationParams.classificationDropdownFilter && classifications.length > 0){
      setTimeout(() => {
        const classificationObject = classifications.find((classification) => classification.description === navigationParams.classificationDropdownFilter);
        toggleCheckboxClassifications(classificationObject);
        dispatch(
          setNavigationParams({
            classificationDropdownFilter: null,
          })
        );
      }, 0);
  }
  }, [navigationParams, classifications]);


  useEffect(() => {
    const closeDropdown = (event) => {
      if (dropdownRefClassifications.current && !dropdownRefClassifications.current.contains(event.target)) {
        setIsOpenClassification(false);
      }
      if (dropdownRefCategories.current && !dropdownRefCategories.current.contains(event.target)) {
        setIsOpenCategories(false);
      }
      if (dropdownRefSolutionCategories.current && !dropdownRefSolutionCategories.current.contains(event.target)) {
        setIsOpenSolutionCategories(false);
      }
      if (dropdownRefSolutionType.current && !dropdownRefSolutionType.current.contains(event.target)) {
        setIsOpenSolutionType(false);
      }
      if (dropdownRefFeatureArea.current && !dropdownRefFeatureArea.current.contains(event.target)) {
        setIsOpenEstimatedHours(false);
      }
    };

    document.addEventListener('click', closeDropdown);
  }, [])

  useEffect(() => {
    cartSolution.subscribe((state) => {
      setProductsInTheCart(state)
    });
  }, []);

  useEffect(() => {
    const route = window.location.pathname
    const splitRoute = route.split("/");
    const nameSra = splitRoute[2] || '';
    if (nameSra === '' && !navigationParams.classificationDropdownFilter) {
      getSolutionProducts();
    }
  }, [queryParams]);


  useEffect(() => {
    if (onSearchCategory === '') {
      addQueryParamSearch('search_text', onSearchCategory)
    }
  }, [onSearchCategory]);


  useEffect(() => {
    if (selectedSolutionCategory?.length === 0 || selectedSolutionType?.length === 0) {
      setCard(originalCardListRef.current)
    }
  }, [selectedSolutionCategory, selectedSolutionType]);


  useEffect(() => {
    if (sraNameSelected !== undefined) {
      getSolutionSraTitle(sraNameSelected)
    }
  }, [sraNameSelected]);

  const getSolutionType = async () => {
    const [error, data] = await apiRequest({
      method: "get",
      url: apiRoutes.solutionType,
    });

    if (data?.status_code === 200) {
      setSolutionType(data?.data)
    }
  }

  const {
    cardTop,
    setCardTop,
    card,
    setCard,
    isLoading,
    error,
  } = useSolutionProduct();

  const getSolutionSra = async () => {
    setLoadingTop(true)
    setLoadingProducts(true)
    setLoadingSRA(true)
    const [error, data] = await apiRequest({
      method: "get",
      url: apiRoutes.solutionProduct,
    });
    let top = []
    let card = []
    if (data?.status_code === 200) {
      data?.data?.map(item => item?.top !== null ? top.push(item) : card.push(item))
      top?.sort((a, b) => a?.top - b?.top);
      originalCardListRef.current = data?.data
      setLoadingTop(false)
      setLoadingProducts(false)
      setLoadingSRA(false)
      setCardTop(top)
      setCard(data?.data)
    }
  }

  useEffect(() => {
    const currentStep = sessionStorage.getItem("currentStep");
    if (cardTop.length > 0 && currentStep === "4") {
      setTimeout(() => {
        controlTour.next(true)
      }, 100);
    }
  }, [cardTop, sessionStorage.getItem("currentStep")]);

  const getSolutionProducts = async () => {
    setCard([])
    setLoadingProducts(true)
    const newQueryString = Object.keys(queryParams)
      .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`)
      .join('&');

    const [error, data] = await apiRequest({
      method: "get",
      url: `${apiRoutes.solutionProduct}?${newQueryString}`,
    });
    let card = []
    if (data?.status_code === 200) {
      setLoadingProducts(false)
      setCard(data?.data)
    }
  }

  const getSolutionSraTitle = async (sraName) => {
    if (sraName !== '') {
      setCard([])
      setLoadingProducts(true)
      const [error, data] = await apiRequest({
        method: "get",
        url: `${apiRoutes.solutionProduct}?title=${sraName}`,
      });
      let card = []
      if (data?.status_code === 200) {
        setLoadingProducts(false)
        setCard(data?.data)
      }
    }
  }

  const getSolutionCategory = async () => {
    const [error, data] = await apiRequest({
      method: "get",
      url: apiRoutes.solutionCategory,
    });

    if (data?.status_code === 200) {
      setCategory(data.data)
    }
  }

  const getOperationCloudProducts = async () => {
    const [error, data] = await apiRequest({
      method: "get",
      url: apiRoutes.operationCloudProducts,
    });

    if (data?.status_code === 200) {
      setOperationCloudProducts(data.data)
    }
  }

  const getClassifications = async () => {
    const [error, data] = await apiRequest({
      method: "get",
      url: apiRoutes.classifications,
    });

    if (data?.status_code === 200) {
      setClassifications(data.data)
    }
  }

  const getOperationCloudAreaProducts = async () => {
    const [error, data] = await apiRequest({
      method: "get",
      url: `${apiRoutes.getOperationCloudArea}`,
    });

    if (data?.status_code === 200) {
      setOperationCloudAreaProducts(data.data)
    }
  }

  const getSolutionFeature = async (productArea) => {
    const [error, data] = await apiRequest({
      method: "get",
      url: `${apiRoutes.solutionFeature}?category_id=${productArea}`,
    });
    if (data?.status_code === 200) {
      setSolutionFeature(data.data)
    }
  }

  const getHours = async () => {
    const [error, data] = await apiRequest({
      method: "get",
      url: apiRoutes.hoursExpire,
    });
    if (data?.status_code === 200) {
      setSolutionHours(data.data)
    }
  }

  const addCart = (item) => {
    setMsgCart(true)
    setSelectProduct(item)
    setProductsInTheCart(prevState => {
      const newState = [...prevState];
      const itemIndex = newState.indexOf(item);

      if (itemIndex === -1) {
        newState.push(item);
      }
      cartSolution.next(newState)
      localStorage.setItem("cart", JSON.stringify(newState));
      return newState;
    });
    setTimeout(() => {
      setMsgCart(false)
    }, 1000);
  }

  const isOpenShowRefineResults = () => {
    setShowRefineResults(!showRefineResults)
  }

  const handleDropdownClickSolutionCategories = () => {
    setIsOpenSolutionCategories(!isOpenSolutionCategories);
  }

  const handleDropdownClickCategories = () => {
    setIsOpenCategories(!isOpenCategories);
  }
  const handleDropdownClickClassifications = () => {
    setIsOpenClassification(!isOpenClassification);
  }

  const handleDropdownClickSolutionType = () => {
    setIsOpenSolutionType(!isOpenSolutionType);
  }

  const handleDropdownClickFeatureArea = () => {
    setIsOpenEstimatedHours(!isOpenEstimatedHours);
  }

  const handleSearchChangeCategories = (e) => {
    setSearchCategories(e.target.value);
  };
  const handleSearchChangeClassifications = (e) => {
    setSearchClassifications(e.target.value);
  };

  const handleSearchFeatureArea = (e) => {
    setSearchEstimatedHours(e.target.value);
  }

  const toggleCheckbox = async (item, setState) => {
    setState(prevState => {
      const newState = [...prevState];
      const itemIndex = newState.indexOf(item);
      if (itemIndex === -1) {
        newState.push(item);
      } else {
        newState.splice(itemIndex, 1);
      }
      return newState;
    });
  };


  const addQueryParam = (key, value) => {

    setQueryParams(prevState => {
      const currentValues = prevState[key] ?
        typeof prevState[key] === 'string' ?
          prevState[key].split(',') :
          Array.isArray(prevState[key]) ?
            prevState[key] :
            [prevState[key]]
        : [];

      const incomingValues = Array.isArray(value) ?
        value[0].split(',') :
        typeof value === 'string' ?
          value.split(',') :
          [value];

      const allValuesExist = incomingValues.every(v =>
        currentValues.includes(v.toString().trim())
      );

      if (allValuesExist) {
        const updatedValues = currentValues.filter(v =>
          !incomingValues.includes(v.toString().trim())
        );

        if (updatedValues.length === 0) {
          const { [key]: removed, ...rest } = prevState;
          return rest;
        }

        return {
          ...prevState,
          [key]: updatedValues.join(',')
        };
      } else {
        const uniqueValues = [...new Set([...currentValues, ...incomingValues])];
        return {
          ...prevState,
          [key]: uniqueValues.join(',')
        };
      }
    });
  };

  const normalizeValue = (value) => {
    return Array.isArray(value) ? value : [value.toString()];
  };

  const toggleCheckboxCategories = async (productArea) => {
    addQueryParam('operation_area_id', normalizeValue(productArea.id));
    toggleCheckbox(productArea.id, setSelectedCategories);
  };

  const toggleCheckboxClassifications = async (classification) => {
    addQueryParam('classification_id', normalizeValue(classification.id));
    toggleCheckbox(classification.id, setSelectedClassifications);
  };

  const addQueryParamSearch = (key, value) => {
    if (value !== undefined && value !== null && value !== '') {
      setQueryParams(prevState => ({
        ...prevState,
        [key]: value
      }));
    } else {
      setQueryParams(prevState => {
        const { [key]: removedParam, ...restParams } = prevState;
        return restParams;
      });
    }
  };

  const handleSearchChangeSolutionCategory = (e) => {
    setSearchSolutionCategory(e.target.value);
  };

  const handleSearchChangeSolutionType = (e) => {
    setSearchSolutionType(e.target.value);
  };

  const toggleCheckboxSolutionCategory = async (solutionCategory) => {
    const index = selectedSolutionCategory.indexOf(solutionCategory?.name);
    if (index !== -1) {
      setSelectedSolutionCategory([]);
      setQueryParams(prevState => {
        const { 'solution_category': removedParam, ...restParams } = prevState;
        return restParams;
      });
    } else {
      setSelectedSolutionCategory([solutionCategory?.name]);
      setQueryParams(prevState => ({
        ...prevState,
        'solution_category': solutionCategory.name
      }));
    }
  }


  const toggleCheckboxSolutionType = async (solutionType) => {
    const index = selectedSolutionType.indexOf(solutionType.name);
    if (index !== -1) {
      setSelectedSolutionType([]);
      setQueryParams(prevState => {
        const { 'solution_type': removedParam, ...restParams } = prevState;
        return restParams;
      });
    } else {
      setSelectedSolutionType([solutionType.name]);
      setQueryParams(prevState => ({
        ...prevState,
        'solution_type': solutionType.name
      }));
    }
  }

  const toggleCheckboxFeatureArea = async (feacture) => {
    toggleCheckbox(feacture.id, setSelectedEstimatedHours)
    addQueryParam('sub_category_id', feacture.id);
  }

  const filteredOptions = (options, searchState) => {
    return options?.filter(option =>
      option?.name ? option?.name?.toLowerCase().includes(searchState?.toLowerCase()) : option?.description?.toLowerCase().includes(searchState?.toLowerCase())
    );
  };


  const toggleSotBySortByAlphabetical = (sort) => {
    handleSort(sort, card)
  }

  const handleSort = (sortByAlphabetical, cardList) => {
    let sortedList = [...cardList];

    if (sortByAlphabetical === 'AZ') {
      sortedList?.sort((a, b) => a?.solution_name?.localeCompare(b?.solution_name));
    } else {
      sortedList?.sort((a, b) => b?.solution_name?.localeCompare(a?.solution_name));
    }
    setCard(sortedList)
  };

  const handleHours = (hours) => {
    setSelectHours(hours);
    const cardList = hours === 'all' ? originalCardListRef.current :
      originalCardListRef.current.filter((item) => item?.solution_hour_cost?.split(' ')[0].trim() === hours);
    setCard(cardList);
  };

  const deleteProduct = (product) => {
    const filterProduct = productsInTheCart?.filter((item) => item?.solution_id !== product)
    setProductsInTheCart(filterProduct)
    cartSolution.next(filterProduct);
    localStorage.setItem("cart", JSON.stringify(filterProduct));
  }

  const getCart = () => {
    const cartString = localStorage.getItem("cart");
    if (cartString) {
      const productsInTheCart = JSON.parse(cartString);
      setProductsInTheCart(productsInTheCart)
      cartSolution.next(productsInTheCart)
    }
  }

  const getDetailProduct = async (idProduct) => {
    setDetailProduct(idProduct)
  }

  const onClearSection = () => {
    setCard([])
    navigate('/solution-gallery', { replace: true })
    getSolutionSra()
    setSelectedClassifications([])
    setSelectedCategories([])
    setSelectedEstimatedHours([])
    setSelectedSolutionCategory([]);
    setSelectedSolutionType([])
    setOnSearchCategory('')
  }

  const onLinkSra = (nameSra) => {
    setQueryParams(prevState => { return {} });
    setSearchClassifications('');
    setSelectedClassifications([]);
    setSearchCategories('');
    setSelectedCategories([]);
    setModalOpenDetailSolution(false)

    setOnSearchCategory(nameSra)
    addQueryParamSearch('search_text', nameSra)
  }

  const returnImg = (img) => {
    const images = {
      'PagerdutyServiceRequest.svg': PagerdutyServiceRequest,
      'ProfessionalServices.svg':  ProfessionalServices,
      'OpenSource.svg': OpenSource,
      'PDU.svg': PDU,
      'SolutionsIcon.svg': Solutions,
      'OnRequestReport.svg': EnterpriseIncidentManagement,
      'SolutionAccelerator.svg': SolutionAccelerator,
      'DevelopersRelations.svg': DevelopersRelations
    };
    return images[img] || PagerdutyServiceRequest;
  }

  const paginatedCardSolution = useMemo(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return card.slice(startIndex, endIndex);
  }, [card, currentPage, itemsPerPage]);


  const arrayHours = ['1', '2', '4', '6', '8']
  const filteredOptionsClassifications = filteredOptions(classifications, searchClassifications);
  const filteredOptionsOperationCloudProducts = filteredOptions(operationCloudAreaProducts, searchCategories);

  return (
    <div className='container__solutionGallery'>
      <ContainerCard>
        <div className="container__hoursLeftHeader">
          <div className="container__welcomeSolutions">Explore our Premium Services Solutions currently available to accelerate deployment, adoption, maturity and your business outcomes using your PagerDuty Operations Cloud platform entitlements.</div>
          <div>
            <div className="text__hoursLeft">
              <span className="hours__left">Hours left: </span>
              <span className="hours_available">{solutionHours?.hours_available || 'none'}</span>
            </div>
            <div className="text__expire">
              <span className="hours__left">Expire: </span>
              <span>{solutionHours?.project_current_active_quarter_end_date || 'none'}</span>
              {/* <span>{solutionHours?.project_active_quarter_date_end || 'none'}</span> */}
            </div>
          </div>
        </div>
      </ContainerCard>
      {cardTop && cardTop.length > 0 &&
        <div className="container__card">
          <TitleApp>Most Requested Solutions from Similar Customers</TitleApp>
          <div className="container__sliderSolution">
            <button
              className="btn__sliderSolution"
              onClick={() =>
                setStartIndex((startIndex - 3 > 0) ? startIndex - 3 : 0)
              }
              disabled={startIndex === 0}
            >
              <FontAwesomeIcon icon={faChevronLeft} />
            </button>
            <div className="container__cardSlider" ref={sliderRef}>
              {!loadingTop && cardTop?.slice(startIndex, startIndex + 3).map((item, indexI) => {
                return (
                  <div key={item.solution_id} className="cardSolution" id={indexI === 0 ? "card__solutionTop" : 'card__solutionTopExtras'}>
                    <div className="vertical__cardSolution" >
                      <div className="solution__infoContainer">
                        <div className="infoContainer_content">
                          <div className="infoContainer__header">
                            <div className="container__topCard">#{item?.top} Most Requested</div>
                            <div className="infoContainer__typeSolution">{item?.classification}</div>
                          </div>
                          {item?.solution_target && item?.solution_hour_cost && (
                            <div className="infoContainer__header">
                              <div className="infoContainer__totalHours">
                                <span className="icon__time">
                                  <Time />
                                </span>
                                {item?.solution_target} | {item?.solution_hour_cost}
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="infoContainer__bodySolution">
                          <div>
                            <img
                              src={returnImg(
                                (() => {
                                  switch (item?.image) {
                                    case 'PagerdutyServiceRequest.jpg':
                                      return 'PagerdutyServiceRequest.svg'
                                    case 'ProfessionalServices.jpg':
                                     return 'ProfessionalServices.svg'
                                    case 'OpenSource.jpg':
                                     return 'OpenSource.svg'
                                    case 'PDU.jpg':
                                     return 'PDU.svg'
                                    case 'Solutions.jpg':
                                      return 'SolutionsIcon.svg'
                                    case 'OnRequestReport.jpg':
                                     return 'OnRequestReport.svg'
                                    case 'SolutionAccelerator.jpg':
                                      return 'SolutionAccelerator.svg'
                                    case 'developers_relations.svg':
                                      return 'DevelopersRelations.svg'
                                    default:
                                      return 'PagerdutyServiceRequest.svg';
                                  }
                                })()
                              )}
                              alt={item?.category_name || "Solution"}
                            />
                          </div>
                          <div className="container__descriptionSolution">
                            <div className='infoContainer__titleBodySolution'>{item?.solution_name}</div>
                            <div className="container__typicalActivities">
                              {item.typical_activities?.map((activities, i) => (
                                <p key={i} className="description__card">
                                  {activities}.
                                </p>
                              ))}
                            </div>
                            {item?.typical_activities.length === 0 && (
                              <p className="description__card">{item.description_card}</p>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="infoContainer__footerSolution">
                        <div
                          className="footerSolution__viewDetails"
                          onClick={() => {
                            setModalOpenDetailSolution(!modalOpenDetailSolution);
                            getDetailProduct(item);
                          }}
                        >
                          View details
                        </div>
                        {productsInTheCart?.some(
                          (product) => product?.solution_id === item?.solution_id
                        ) ? (
                          <div
                            className="footerSolution__addToCart"
                            onClick={() => deleteProduct(item?.solution_id)}
                          >
                            <FontAwesomeIcon icon={faTrash} />
                          </div>
                        ) : (
                          <div
                            className="footerSolution__addToCart"
                            onClick={() => addCart(item)}
                          >
                            Add to cart
                          </div>
                        )}
                      </div>
                      {msgCart && productsInTheCart?.some(product => product?.solution_id === item?.solution_id) && selectProduct?.solution_id === item?.solution_id &&
                        <div className="product__added ">Product added to cart</div>}
                    </div>
                  </div>
                );
              })}
              {loadingTop && <SkeletonTop />}
              {cardTop?.length === 0 && !loadingTop && <div className="not__data">We have not found any SRA</div>}
            </div>
            <button
              className="btn__sliderSolution"
              onClick={() => setStartIndex(startIndex + 3)}
              disabled={startIndex + 3 >= (cardTop?.length - 1)}
            >
              <FontAwesomeIcon icon={faChevronRight} />
            </button>
          </div>
        </div>
      }
      <div className="container__secondSolution">
        <ContainerCard>
          <StyledHeaderContainer>
          <TitleApp>Discover Available Solutions to Accelerate your Business Objectives</TitleApp>
            <StyledInfoButton onClick={() => openDialog('solutionGallery')}>
              <FontAwesomeIcon icon={faCircleInfo} />
            </StyledInfoButton>
          </StyledHeaderContainer>
          
          <div className="container__searchSolutionGallery">
            <div className="input__containerSolutionGallery">
              <div className="container__iconGlass"><FontAwesomeIcon icon={faMagnifyingGlass} /></div>
              <input
                name="search-form"
                id="search-form"
                className="searchSolutionGallery__input"
                placeholder="Search by current state challenges, pain points, future state outcomes, product offerings or features"
                value={onSearchCategory}
                onChange={(e) => setOnSearchCategory(e.target.value)}
              />
            </div>
            <div>
              <button
                disabled={onSearchCategory === ''}
                onClick={() => addQueryParamSearch('search_text', onSearchCategory)} className="button__searchSolution">Search</button>
            </div>
          </div>
          <div className="container__selectGallery">
            <div>
              <span className="title__select">Classification</span>
              <div className="container__solutionCategories">
                <div className="multiSelect__filterSolutionGallery" ref={dropdownRefClassifications}>
                  <div className="dropdown-toggleButtonSolutionCategories" onClick={() => !loadingProducts && handleDropdownClickClassifications()} >
                    <div className="container__buttonFilter">
                      {selectedClassifications?.map(filter => (
                        filteredOptionsClassifications?.map(item => (
                          filter === item.id &&
                          <button disabled={loadingProducts} className='button__selectedItem'>
                            <p className="item__filter">{item.description} <FontAwesomeIcon onClick={() => toggleCheckboxClassifications(item)} icon={faTimes} /></p>
                          </button>
                        ))
                      ))}
                    </div>
                  </div>
                  {isOpenClassification && (
                    <div className="dropdown__solutionCategories_solutionGallery">
                      <input
                        className="search__subdomainHomeFilter"
                        type="text"
                        placeholder="Search classifications..."
                        value={searchClassifications}
                        onChange={handleSearchChangeClassifications}
                      />
                      <ul>
                        {filteredOptionsClassifications
                          ?.sort((a, b) => a.description.localeCompare(b.description))
                          .map(option => (
                            <li key={option.project_id}>
                              <label>
                                <input
                                  type="checkbox"
                                  checked={selectedClassifications.includes(option?.id)}
                                  onChange={() => toggleCheckboxClassifications(option)}
                                  disabled={loadingProducts}
                                />
                                <span className="customer_name">{option?.description}</span>
                              </label>
                            </li>
                          ))}
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div>
              <span className="title__select">Operations Cloud Product Area</span>
              <div className="container__solutionCategories">
                <div className="multiSelect__filterSolutionGallery" ref={dropdownRefCategories}>
                  <div
                    className="dropdown-toggleButtonSolutionCategories"
                    onClick={() => !loadingProducts && handleDropdownClickCategories()}
                    style={{
                      cursor: 'pointer',
                      opacity: '1'
                    }}
                  >
                    <div className="container__buttonFilter">
                      {selectedCategories?.map(filter => (
                        filteredOptionsOperationCloudProducts?.map(item => (
                          filter === item.id &&
                          <button key={item.id} disabled={loadingProducts} className='button__selectedItem'>
                            <p className="item__filter">
                              {item.name}
                              <FontAwesomeIcon
                                onClick={(e) => {
                                  e.stopPropagation(); // Prevent dropdown from opening/closing
                                  toggleCheckboxCategories(item);
                                }}
                                icon={faTimes}
                              />
                            </p>
                          </button>
                        ))
                      ))}
                    </div>
                  </div>
                  {isOpenCategories && (
                    <div className="dropdown__solutionCategories_solutionGallery">
                      <input
                        className="search__subdomainHomeFilter"
                        type="text"
                        placeholder="Search categories..."
                        value={searchCategories}
                        onChange={handleSearchChangeCategories}

                      />
                      <ul>
                        {filteredOptionsOperationCloudProducts?.sort((a, b) => a - b).map(option => (
                          <li key={option.project_id}>
                            <label>
                              <input
                                type="checkbox"
                                checked={selectedCategories.includes(option?.id)}
                                onChange={() => toggleCheckboxCategories(option)}
                                disabled={loadingProducts}
                              />
                              <span className="customer_name">{option?.name}</span>
                            </label>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="container__buttonSearchSolution">
            <div className="clear__fieldsSolution" onClick={() => onClearSection()}>Clear Fields</div>
          </div>
          <div className="container__paginationSolution">
            <div className="container__refine">
              <div>{paginatedCardSolution.length} Services found</div>
              <div className="container__paginationSolution">
                <div className="filter__container">
                  <button className="filter__button" onClick={() => setIsOpenDropdown(!isOpenDropdown)} >
                    Refine Results <span className="filter__icon"> <Filter /></span>
                  </button>
                  {isOpenDropdown && (
                    <div className="filter__dropdown">
                      <div className="dropdown__section">
                        <p>Sort By</p>
                        <label>
                          <input
                            value="AZ"
                            type="radio"
                            onChange={(e) => toggleSotBySortByAlphabetical(e.target.value)}
                            name="sort" /> A - Z
                        </label>
                        <label>
                          <input
                            onChange={(e) => toggleSotBySortByAlphabetical(e.target.value)}
                            type="radio"
                            name="sort"
                            value="ZA" /> Z - A
                        </label>
                      </div>
                      <div className="dropdown__section">
                        <p>Hours Estimate (min)</p>
                        <div className="container__itemFilter">
                          <input
                            type="radio"
                            onChange={() => handleHours('all')}
                            value={'all'}
                            checked={selectHours === 'all'}
                            className="input__checkbox" />
                          <div className="text__checkbox">All</div>
                        </div>
                      </div>
                      {arrayHours?.map(hour => (
                        <div className="container__itemFilter">
                          <div>
                            <input
                              onChange={() => handleHours(hour)}
                              type="radio"
                              checked={selectHours === hour}
                              value={hour}
                              className="input__checkbox" /></div>
                          <div className="text__checkbox">{hour} {hour > 1 ? "Hours" : "Hour"} (min)</div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div>
              <UxPagination
                productsPage={itemsPerPage}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                totalProducts={card?.length || 0}
              />
            </div>
          </div>

          <div className="container__cardSolution">
            {card.length > 0 && paginatedCardSolution?.map((item, index) => {
              return (

                <div key={item.solution_id} className="cardSolution">
                  <div className="vertical__cardSolution" >
                    <div className="solution__infoContainer">
                      <div className="infoContainer_content">
                        <div className="infoContainer__header">
                          <div className="infoContainer__typeSolution">{item?.classification}</div>
                        </div>
                        {item?.solution_target && item?.solution_hour_cost && (
                          <div className="infoContainer__header">
                            <div className="infoContainer__totalHours">
                              <span className="icon__time">
                                <Time />
                              </span>
                              {item?.solution_target} | {item?.solution_hour_cost}
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="infoContainer__bodySolution">
                        <div className="image-section">
                          <img
                            src={returnImg(
                              (() => {
                                switch (item?.image) {
                                  case 'PagerdutyServiceRequest.jpg':
                                    return 'PagerdutyServiceRequest.svg'
                                  case 'ProfessionalServices.jpg':
                                   return 'ProfessionalServices.svg'
                                  case 'OpenSource.jpg':
                                   return 'OpenSource.svg'
                                  case 'PDU.jpg':
                                   return 'PDU.svg'
                                  case 'Solutions.jpg':
                                    return 'SolutionsIcon.svg'
                                  case 'OnRequestReport.jpg':
                                   return 'OnRequestReport.svg'
                                  case 'SolutionAccelerator.jpg':
                                    return 'SolutionAccelerator.svg'
                                  case 'developers_relations.svg':
                                    return 'DevelopersRelations.svg'
                                  default:
                                    return 'PagerdutyServiceRequest.svg';
                                }
                              })()
                            )}
                            alt={item?.category_name || "Solution"}
                          />
                        </div>
                        <div className="container__descriptionSolution">
                          <div className='infoContainer__titleBodySolution'>{item?.solution_name}</div>
                          <div className="container__typicalActivities">
                            {item.typical_activities.map((activities, i) => (
                              <p key={i} className="description__card">
                                {activities}.
                              </p>
                            ))}
                          </div>
                          {item?.typical_activities.length === 0 && (
                            <p className="description__card">{item.description_card}</p>
                          )}
                        </div>

                      </div>
                    </div>

                    <div className="infoContainer__footerSolution">
                      <div
                        className="footerSolution__viewDetails"
                        onClick={() => {
                          setModalOpenDetailSolution(!modalOpenDetailSolution);
                          getDetailProduct(item);
                        }}
                      >
                        View details
                      </div>
                      {productsInTheCart?.some(
                        (product) => product?.solution_id === item?.solution_id
                      ) ? (
                        <div
                          className="footerSolution__addToCart"
                          onClick={() => deleteProduct(item?.solution_id)}
                        >
                          <FontAwesomeIcon icon={faTrash} />
                        </div>
                      ) : (
                        <div
                          className="footerSolution__addToCart"
                          onClick={() => addCart(item)}
                        >
                          Add to cart
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                // ) 
              )
            }
            )}
          </div>
          {LoadingSRA && card?.length === 0 && <SkeletonTop />}
          {loadingProducts && card?.length === 0 && <SkeletonTop />}
          {card.length === 0 && !loadingProducts && !LoadingSRA && (
            <div className="not__data">We have not found any SRA</div>
          )}
        </ContainerCard>
      </div>
      {modalOpenDetailSolution &&
        <div className="container__detail">
          <section className="container__section">
            <div className="modal__containerDetail">
              <div className="detail__categoryName">
                <div>{detailProduct.solution_classification_id === 1 && `${detailProduct?.classification}:`} {detailProduct?.classification} {detailProduct.solution_classification_id === 1 && "Service Request Accelerator (SRA)"}</div>
                <di onClick={() => setModalOpenDetailSolution(false)}><FontAwesomeIcon icon={faX} /></di>
              </div>
              <div className="container__subHeader">
                {detailProduct?.solution_target && detailProduct?.solution_hour_cost &&
                  <div className="container__domainAndHour">
                    <div className="domain" >{detailProduct?.solution_target}</div>
                    <div className="hour">{detailProduct?.solution_hour_cost}</div>
                  </div>}
                {detailProduct.solution_classification_id === 1 && <div className="solutionDetail">Professional Services Service Request</div>}
              </div>
              <div className="detail__header">{detailProduct?.solution_name}
              </div>

              <div className="current__future">
                {detailProduct?.current_state?.length > 0 &&
                  <div className="container__currentFuture current">
                    <div className="title__currentFuture">Current State</div>
                    {detailProduct?.current_state?.map((item, index) => (
                      <div key={index} className="item__current__future">{item}</div>
                    ))}
                  </div>}
                {detailProduct?.future_state?.length > 0 &&
                  <div className="container__currentFuture future">
                    <div className="title__currentFuture">Future State</div>
                    {detailProduct?.future_state?.map((item, index) => (
                      <div key={index} className="item__current__future">{item}</div>
                    ))}
                  </div>}
              </div>
              <div className="container__why">
                {detailProduct?.typical_activities?.length > 0 &&
                  <div className="why__info">
                    {detailProduct.solution_classification_id === 1 && <div className="why__title">Typical Activities</div>}
                    {detailProduct.solution_classification_id === 1 && <div className="subtitle__activities">A Professional Services consultant will:</div>}
                    {detailProduct.solution_classification_id === 1 &&
                      <di>
                        <ul className="why__ul">
                          {detailProduct?.typical_activities?.map((item, index) => (
                            <li key={index} className="why__li">{item}</li>
                          ))}
                        </ul>
                      </di>}
                    {detailProduct.solution_classification_id !== 1 &&
                      <div className="not__sra">
                        {detailProduct?.typical_activities?.map((item, index) => (
                          <div key={index} >{item}</div>
                        ))}
                      </div>}
                  </div>}
                {detailProduct?.typical_activities?.length === 0 &&
                  <div className="not__sra">{detailProduct.description_card}</div>
                }
                {detailProduct?.typical_activities?.length === 0 &&
                  detailProduct.cards_field.map(item => (
                    <div style={{ marginTop: "10px" }}>{item}</div>
                  ))
                }
              </div>
              {detailProduct?.next_best?.length > 0 &&
                <div className="container__whatDoes">
                  <div className="why__title">Next Best SRA(s)</div>
                  <di>
                    {detailProduct?.next_best?.map((item, index) => (
                      <div onClick={() => onLinkSra(item)}><a key={index} className="next_best">{item}</a></div>
                    ))}
                  </di>
                </div>}
              <div className="footer__detail">
                <img className="logo__footer" src={logo} />
              </div>
            </div>
          </section>
        </div>}

        <Dialog
          isOpen={isDialogOpen('solutionGallery')}
          onClose={closeDialog}
          title={dialogContent.solutionGallery.title}
          description={dialogContent.solutionGallery.description}
        />
      <div className="container__copySolution"><Copyright /></div>
    </div>
  )
}
