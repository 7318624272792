import React, { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "rsuite/dist/rsuite-no-reset.min.css";
import Filter from "../../assets/SVGs/Icons/Filter";
import Calendar from "../../assets/SVGs/Icons/Calendar";
import { v4 as uuidv4 } from "uuid";
import {
  faBullseye,
  faMagnifyingGlass,
  faEllipsis,
  faChevronDown,
  faChevronUp,
} from "@fortawesome/free-solid-svg-icons";
import { deepCopy } from "../../utils/shared-functions";
import Checkbox from "../Checkbox/Checkbox";
import { DateRangePicker } from "rsuite";
import Dropdown from "../Dropdown";
import { Tooltip } from '../../components/Tooltip';
import "./GenericTable.css";

const GenericTable = forwardRef((props, ref) => {
  const {
    rowsArray,
    columnsArray,
    iSortDescending = true,
    tableHeight = "auto",
    elementOnClick,
    dontShowPagination = false,
    onActionClick,
    title,
    isLoading = false,
    filterDateKey,
    onSearchChange,
    onPaginationChange,
    actualPage,
    setActualPage,
    itemsPerPage,
    setItemsPerPage,
    totalItems, 
    isSortable = false,
  } = props;

  const [pages, setPages] = useState(0);
  const [deepCopuRowsArray, setDeepCopyRowsArray] = useState(deepCopy(rowsArray));
  const [currentSelectedAction, setCurrentSelectedAction] = useState(null);
  const [currentDateFilter, setCurrentDateFilter] = useState(null);
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [selectedColumnFilterIndex, setSelectedColumnFilterIndex] = useState(0);
  const [sortDescending, setSortDescending] = useState(iSortDescending);
  const [originalData, setOriginalData] = useState(deepCopy(rowsArray));
  const [paginationCopy, setPaginationCopy] = useState(deepCopy(originalData));
  const [forceUpdate, setForceUpdate] = useState(true);
  const [searchTextValue, setSearchTextValue] = useState("");
  const [adjustedStartPage, setAdjustedStartPage] = useState(0);
  const [adjustedEndPage, setAdjustedEndPage] = useState(0);
  const [paginationPages, setPaginationPages] = useState([]);
  const [tooltipContent, setTooltipContent] = useState('');
  const [showTooltip, setShowTooltip] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });


  useEffect(() => {
    const totalPages = Array.from(
      { length: adjustedEndPage - adjustedStartPage + 1 },
      (_, i) => adjustedStartPage + i,
    );
    setPaginationPages([...totalPages]);
  }, [adjustedStartPage, adjustedEndPage]);

  useEffect(() => {
    const totalPages = Math.ceil(totalItems / itemsPerPage);
    setPages(totalPages);
    const startPage = Math.max(1, actualPage - 2);
    const endPage = Math.min(totalPages - 1, actualPage + 2);
    setAdjustedStartPage(actualPage <= 2 ? 1 : startPage);
    setAdjustedEndPage(actualPage >= totalPages - 3 ? totalPages - 1 : endPage);
  }, [actualPage, totalItems, itemsPerPage]);

  useEffect(() => {
    if (onPaginationChange) {
      onPaginationChange(actualPage);
    }
  }, [actualPage, onPaginationChange]);


  useEffect(() => {
    setDeepCopyRowsArray(deepCopy(rowsArray));
  }, [rowsArray]);

  const getFiltrableOptions = () => {
    const filtrableOptionsObject = {};
    const columnsToUse = columnsArray
      .filter((a) => a.filtrable)
      .map((a) => a.rowKey);
    columnsToUse.forEach((b) => {
      const alreadyAdded = [];
      originalData.forEach((a) => {
        if (!filtrableOptionsObject[b]) filtrableOptionsObject[b] = [];
        if (
          !filtrableOptionsObject[b].includes(
            typeof a[b] === "object" && a[b].value ? a[b].value : a[b]
          ) &&
          !alreadyAdded.includes(
            typeof a[b] === "object" && a[b].value ? a[b].value : a[b]
          )
        ) {
          alreadyAdded.push(
            typeof a[b] === "object" && a[b].value ? a[b].value : a[b]
          );
          filtrableOptionsObject[b].push({
            key: typeof a[b] === "object" && a[b].value ? a[b].value : a[b],
            checked: false,
            label: typeof a[b] === "object" && a[b].value ? a[b].value : a[b],
          });
        }
      });
    });
    return filtrableOptionsObject;
  };


  useEffect(() => {
    onPaginationChange(actualPage);
  }, [actualPage]);


  const [filtrableOptions] = useState(getFiltrableOptions());

  useImperativeHandle(ref, () => ({
    setForceUpdateFunction,
    sortArrayFunction,
    getData,
    resetTable,
  }));

  useEffect(() => {
    setPaginationCopy(rowsArray.map((a) => ({ ...a })).sort(sortingFunction));
  }, [iSortDescending, sortDescending, rowsArray]);

  const getData = () => {
    return rowsArray.map((a) => ({ ...a }));
  };

  const resetTable = () => {
    setSelectedColumnFilterIndex(0);
    setSortDescending(iSortDescending);
    setSearchTextValue("");
    setCurrentDateFilter(null);
  };

  const setForceUpdateFunction = () => {
    setForceUpdate(!forceUpdate);
  };

  useEffect(() => {
    sortArrayFunction();
  }, [selectedColumnFilterIndex, sortDescending]);

  useEffect(() => {
    searchByKeys();
  }, [searchTextValue, currentDateFilter]);

  const isValidDate = (dateString) => {
    const parsedDate = new Date(dateString);
    return !isNaN(parsedDate.getTime());
  };

  const searchByKeys = () => {
    let startDate = null;
    let endDate = null;
    if (filterDateKey && currentDateFilter) {
      startDate = new Date(currentDateFilter[0]);
      endDate = new Date(currentDateFilter[1]);
    }

    let filteredArray = originalData.filter((a) => {
      const combinedString = Object.values(a)
      .map((value) =>
        (value && typeof value === "object" && value.value !== undefined
          ? isValidDate(value.value)
            ? formatDay(new Date(value.value))
            : value.value
          : isValidDate(value)
          ? formatDay(new Date(value))
          : value
        )
          ?.toString()
          ?.toLowerCase() || ''
      )
      .join(" ");
      const searchWords = searchTextValue.toLowerCase().split(" ");
      return searchWords.every((word) => combinedString.includes(word));
    });

    filteredArray = filteredArray.filter((a) => {
      let isOk = true;
      if (filterDateKey && currentDateFilter && startDate && endDate) {
        const currentDate = new Date(
          typeof a[filterDateKey] === "object" && a[filterDateKey].value
            ? a[filterDateKey].value || ""
            : a[filterDateKey]
        );
        if (!(currentDate >= startDate && currentDate <= endDate)) {
          isOk = false;
        }
      }
      Object.keys(filtrableOptions).forEach((b) => {
        if (filtrableOptions[b].filter((c) => c.checked).length > 0) {
          if (
            !filtrableOptions[b]
              .filter((c) => c.checked)
              .map((c) => c.key)
              .includes(
                typeof a[b] === "object" && a[b].value ? a[b].value : a[b]
              )
          ) {
            isOk = false;
          }
        }
      });

      return isOk;
    });

    setPaginationCopy(filteredArray);
  };

  const sortingFunction = (a, b) => {
    const getValue = (obj, index) => {
      const column = columnsArray[index];
      const value =
        typeof obj[column.rowKey] === "object" && obj[column.rowKey].value
          ? obj[column.rowKey].value
          : obj[column.rowKey];
      if (column.type === "Number") return parseInt(value);
      if (column.type === "Date") return new Date(value).getTime();
      if (column.type === "Array") return value.length;
      return value;
    };

    const AValueSelected = getValue(a, selectedColumnFilterIndex);
    const BValueSelected = getValue(b, selectedColumnFilterIndex);

    if (
      typeof AValueSelected === "string" &&
      typeof BValueSelected === "string"
    ) {
      return sortDescending
        ? AValueSelected.localeCompare(BValueSelected)
        : BValueSelected.localeCompare(AValueSelected);
    }

    if (AValueSelected !== BValueSelected) {
      return sortDescending
        ? AValueSelected - BValueSelected
        : BValueSelected - AValueSelected;
    }

    const AValueFirst = getValue(a, 0);
    const BValueFirst = getValue(b, 0);

    return sortDescending
      ? AValueFirst - BValueFirst
      : BValueFirst - AValueFirst;
  };

  const sortArrayFunction = () => {
    setOriginalData([...paginationCopy].sort(sortingFunction));
  };

  const formatDay = (date) => {
    if (!date) return "";

    const parsedDate = new Date(date);
    if (isNaN(parsedDate.getTime())) return "";

    return parsedDate.toLocaleDateString("en-US", {
      month: "short",
      day: "2-digit",
      year: "numeric",
    });
  };

  const clickActionFunction = (action, row) => {
    if (onActionClick) onActionClick(action, row);
    setCurrentSelectedAction(null);
  };

  const handleMouseEnter = (e, content) => {
    const rect = e.currentTarget.getBoundingClientRect();
    const tooltipHeight = 200;
    const offset = 20;

    setTooltipContent(content);
    setShowTooltip(true);
    setTooltipPosition({
      x: rect.left,
      y: rect.top - tooltipHeight - 5
    });
  };
  

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  return (
    <div className="table-div" style={{ height: tableHeight }}>
      <h2 className="title">{title}</h2>
      <div className="table-actions-div">
        <div className="searchbar-container">
          <div className="searchbar-options-container">
          </div>
          {false && <div className="custom-filter-container">
            <button className="custom-filter-button" onClick={() => setFiltersOpen(!filtersOpen)}>
              <div className="custom-filter-icon-container">
                <Filter />
              </div>
              Filters
            </button>
            {filtersOpen && (
              <div className="custom-filter-options">
                <div className="custom-filter-columns">
                  {filtrableOptions &&
                    Object.keys(filtrableOptions).map((a) => (
                      <div className="custom-filter-column" key={uuidv4()}>
                        <span className="custom-filter-column-label">
                          {
                            columnsArray.filter(
                              (ab) =>
                                (typeof ab.rowKey === "object"
                                  ? ab.rowKey?.value || ""
                                  : ab.rowKey) === a
                            )[0].label
                          }
                        </span>
                        {filtrableOptions[a].map((b) => (
                          <Checkbox
                            label={b.key}
                            key={uuidv4()}
                            checked={b.checked}
                            handleClick={() => {
                              b.checked = !b.checked;
                              setForceUpdateFunction();
                            }}
                          />
                        ))}
                      </div>
                    ))}
                </div>
                <button className="custom-filter-columns-button" onClick={() => searchByKeys()}>
                  Apply
                </button>
              </div>
            )}
          </div>}
          {false && (
            <div className={`date-range-container ${!currentDateFilter ? 'show' : ''}`}>
              <Calendar />
              <DateRangePicker
                onChange={(date) => setCurrentDateFilter(date)}
                value={currentDateFilter}
                placement="bottomEnd"
                size="md"
                format="MMM dd, yyyy"
              />
            </div>
          )}
        </div>
      </div>
      <div className={`table-container ${!isLoading ? 'overflow' : ''}`}>
        <div className="titles">
          {columnsArray.map((column, i) => (
            <div
              key={uuidv4()}
              className="title-columns"
              style={{
                width: !column.widthPX || column.widthPX <= 100 ? "100px" : `${column.widthPX}px`,
              }}
            >
              <span className="text-label-row-title">{column.label}</span>
              {isSortable && (
                <div className="div-icons-title">
                  {i !== selectedColumnFilterIndex ? (
                    <button
                      className="button-table"
                      onClick={() => setSelectedColumnFilterIndex(i)}
                    >
                      <FontAwesomeIcon icon={faBullseye} />
                    </button>
                  ) : sortDescending ? (
                    <button
                      className="button-table"
                      onClick={() => setSortDescending(false)}
                    >
                      <FontAwesomeIcon icon={faChevronDown} />
                    </button>
                  ) : (
                    <button
                      className="button-table"
                      onClick={() => setSortDescending(true)}
                    >
                      <FontAwesomeIcon icon={faChevronUp} />
                    </button>
                  )}
                </div>
              )}
            </div>
          ))}
        </div>
        {!isLoading && (
          <div className="table-row-container">
            {deepCopuRowsArray.map((row, e) => (
              <div
                key={uuidv4()}
                className="generic-row"
                onClick={() => elementOnClick && elementOnClick(row)}
              >
                {columnsArray.map((column) => {
                  const cellContent = column.type === "Date"
                    ? formatDay(
                        typeof row[column.rowKey] === "object"
                          ? row[column.rowKey]?.value || ""
                          : row[column.rowKey]
                      )
                    : typeof row[column.rowKey] === "object"
                    ? row[column.rowKey]?.value || ""
                    : row[column.rowKey];

                  return (
                    <div
                      key={uuidv4()}
                      onMouseEnter={(e) => column.label === "Case Subject" && handleMouseEnter(e, cellContent)}
                      onMouseLeave={handleMouseLeave}
                      className={`generic-column ${column.label === "Case Subject" ? 'with-tooltip' : ''}`}
                      style={{
                        width: !column.widthPX || column.widthPX <= 100
                          ? "100px"
                          : `${column.widthPX}px`,
                      }}
                    >

                      <label
                        className="text-label-row"
                        style={{
                          color: typeof row[column.rowKey] === "object" &&
                            row[column.rowKey] &&
                            row[column.rowKey].color
                              ? row[column.rowKey].color
                              : "#000",
                          ...(column.label === "Case Subject" && {
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            display: 'block',
                            width: '100%'
                          })
                        }}
                      >
                        {cellContent}
                      </label>
                    </div>
                  );
                })}
              </div>
            ))}
          </div>
      )}
    {showTooltip && (
        <div 
          className="custom-tooltip"
          style={{
            position: 'absolute',
            left: `${tooltipPosition.x}px`,
            top: `${tooltipPosition.y}px`,
            transform: 'translateX(-70%)',
          }}
        >
          {tooltipContent}
        </div>
      )}
      {isLoading && (
        <div className="progress-wrapper">
          <div className="progress-bar-container">
            <div className="progress-bar"></div>
          </div>
        </div>
      )}
    </div>
  </div>
);
});

GenericTable.displayName = "GenericTable";

export default GenericTable;