// MultiSelect.tsx
import React, { forwardRef, useState, useRef, useEffect } from 'react';
import { MultiSelectProps, Option } from './MultiSelect.model';
import {
  StyledMultiSelectWrapper,
  StyledMultiSelectLabel,
  StyledMultiSelectContainer,
  StyledDropdown,
  StyledOption,
  StyledCheckboxOption,
  StyledSelectedTags,
  StyledTag,
  StyledTagRemove,
  StyledErrorMessage,
  StyledPlaceholder,
  StyledTagsContainer,
  StyledInputContent,
  StyledIconWrapper,
  StyledSearchContainer,
  StyledDropdownAndTagsContainer,
} from './MultiSelect.styled';
import {
  XMarkIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from '@heroicons/react/24/outline';



export const MultiSelect = forwardRef<HTMLDivElement, MultiSelectProps>(
  (
    {
      label,
      error,
      name,
      options,
      value,
      onChange,
      placeholder = 'Select options...',
      required,
      disabled,
      maxItems,
      variant = { display: 'default', selection: 'default' },
      selectedDisplay = 'count',
      labelsLimit = 2,
      showSearch,
      id
    },
    ref
  ) => {
    const [isOpen, setIsOpen] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const wrapperRef = useRef<HTMLDivElement>(null);

    const filteredOptions = options.filter((option) =>
      option.label.toLowerCase().includes(searchValue.toLowerCase())
    );

    useEffect(() => {
      const handleClickOutside = (event: MouseEvent) => {
        if (
          wrapperRef.current &&
          !wrapperRef.current.contains(event.target as Node)
        ) {
          setIsOpen(false);
          setSearchValue('');
        }
      };

      document.addEventListener('mousedown', handleClickOutside);
      return () =>
        document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearchValue(e.target.value);
    };

    const handleSearchClear = () => {
      setSearchValue('');
    };

    const handleToggleOption = (option: Option) => {
      if (value.some((v) => v.value === option.value)) {
        onChange(value.filter((v) => v.value !== option.value));
      } else if (!maxItems || value.length < maxItems) {
        onChange([...value, option]);
      }
    };

    const handleRemoveTag = (optionToRemove: Option, e: React.MouseEvent) => {
      e.stopPropagation();
      onChange(value.filter((v) => v.value !== optionToRemove.value));
    };

    const toggleDropdown = () => {
      if (!disabled) {
        setIsOpen(!isOpen);
        if (!isOpen) {
          setSearchValue('');
        }
      }
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
      if (e.key === 'Enter' || e.key === ' ') {
        toggleDropdown();
      } else if (e.key === 'Escape') {
        setIsOpen(false);
        setSearchValue('');
      }
    };

    const getInputText = () => {
      if (value.length === 0) {
        return placeholder;
      }

      switch (selectedDisplay) {
        case 'labels':
          return value.map((v) => v.label).join(', ');

        case 'labelsWithLimit':
          if (value.length <= labelsLimit) {
            return value.map((v) => v.label).join(', ');
          }
          return `${value.length} items selected`;

        case 'count':
        default:
          const itemText = value.length === 1 ? 'item' : 'items';
          return `${value.length} ${itemText} selected`;
      }
    };

    const renderOption = (option: Option) => {
      const isSelected = value.some((v) => v.value === option.value);

      return (
        <StyledOption
          key={option.value}
          onClick={() => handleToggleOption(option)}
          selected={isSelected}
        >
          {option.label}
        </StyledOption>
      );
    };

    const renderTags = () => {
      if (value.length === 0) {
        return <StyledPlaceholder>{placeholder}</StyledPlaceholder>;
      }

      return value.map((option) => (
        <StyledTag key={option.value}>
          {option.label}
          <StyledTagRemove
            onClick={(e) => handleRemoveTag(option, e)}
            type='button'
            aria-label={`Remove ${option.label}`}
          >
            <XMarkIcon />
          </StyledTagRemove>
        </StyledTag>
      ));
    };

    const renderDropdown = () => {
      if (!isOpen || disabled) return null;


      return (
        <StyledDropdown
          role='listbox'
          id={`${id}-listbox`}
          aria-multiselectable='true'
        >
      
          {filteredOptions.length === 0 ? (
            <StyledOption disabled>No options available</StyledOption>
          ) : (
            filteredOptions.map(renderOption)
          )}
        </StyledDropdown>
      );
    };

    return (
      <StyledMultiSelectWrapper ref={wrapperRef} id={id}>
        {label && (
          <StyledMultiSelectLabel required={required}>
            {label}
          </StyledMultiSelectLabel>
        )}

        <StyledMultiSelectContainer
          onClick={toggleDropdown}
          onKeyDown={handleKeyDown}
          hasError={!!error}
          isOpen={isOpen}
          disabled={disabled}
          tabIndex={0}
          role='combobox'
          aria-expanded={isOpen}
          aria-haspopup='listbox'
          aria-controls={`${name}-listbox`}
        >
          {variant.display === 'tags-below' ? (
            <StyledInputContent>
              <StyledPlaceholder $hasValue={value.length > 0}>
                {getInputText()}
              </StyledPlaceholder>
              <StyledIconWrapper>
                {isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
              </StyledIconWrapper>
            </StyledInputContent>
          ) : (
            <>
              <StyledSelectedTags>{renderTags()}</StyledSelectedTags>
              <StyledIconWrapper>
                {isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
              </StyledIconWrapper>
            </>
          )}
        </StyledMultiSelectContainer>

        <StyledDropdownAndTagsContainer>
          {renderDropdown()}
          {variant.display === 'tags-below' && value.length > 0 && (
            <StyledTagsContainer>{renderTags()}</StyledTagsContainer>
          )}
          {error && <StyledErrorMessage>{error}</StyledErrorMessage>}
        </StyledDropdownAndTagsContainer>
      </StyledMultiSelectWrapper>
    );
  }
);

MultiSelect.displayName = 'MultiSelect';

export default MultiSelect;