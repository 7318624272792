// MultiSelect.styled.ts
import styled, { css } from 'styled-components';
import {
  baseInputStyles,
  StyledInputWrapper,
  StyledInputLabel,
  StyledErrorMessageInput,
  INPUT_BACKGROUND_COLOR,
  INPUT_TEXT_COLOR,
  INPUT_BORDER_COLOR,
  ERROR_MESSAGE_INPUT_COLOR,
  ERROR_MESSAGE_INPUT_BACKGROUND_COLOR,
  FOCUS_INPUT_COLOR,
} from '../Input/Input.styled';

export const FOCUS_INPUT_BACKGROUND_COLOR = '#E5F2E9';

export const StyledMultiSelectWrapper = styled(StyledInputWrapper)`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const StyledMultiSelectLabel = StyledInputLabel;

export const StyledMultiSelectContainer = styled.div<{
  hasError?: boolean;
  isOpen?: boolean;
  disabled?: boolean;
}>`
  ${baseInputStyles}
  min-height: 55px;
  padding: 8px 16px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  &:focus {
    border-color: rgb(169 165 165);
  }

  ${({ isOpen, hasError }) =>
    isOpen &&
    css`
      border-color: ${hasError ? ERROR_MESSAGE_INPUT_COLOR : FOCUS_INPUT_COLOR};
      box-shadow: 0 0 0 2px
        ${hasError
          ? ERROR_MESSAGE_INPUT_BACKGROUND_COLOR
          : FOCUS_INPUT_BACKGROUND_COLOR};
    `}
`;

export const StyledDropdownAndTagsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: relative;
`;

export const StyledSearchContainer = styled.div`
  padding: 8px;
  border-bottom: 1px solid ${INPUT_BORDER_COLOR};
`;

export const StyledInputContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  width: 100%;
  min-height: 24px;
`;

export const StyledIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 100%;
  color: #6c757d;

  svg {
    width: 20px;
    height: 20px;
  }
`;

export const StyledDropdown = styled.div`
  width: 100%;
  background-color: ${INPUT_BACKGROUND_COLOR};
  border: 1px solid ${INPUT_BORDER_COLOR};
  border-radius: 8px;
  max-height: 200px;
  overflow-y: auto;
  z-index: 1000;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-top: 4px;
  position: absolute;
`;

export const StyledSelectedTags = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  flex: 1;
  align-items: center;
`;

export const StyledTagsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  min-height: 32px;
  padding: 4px 0;
  transition: all 0.2s ease-in-out;
  margin-top: 4px;
`;

export const StyledTag = styled.span`
  display: inline-flex;
  align-items: center;
  gap: 4px;
  padding: 4px 8px;
  background-color: ${FOCUS_INPUT_BACKGROUND_COLOR};
  color: ${INPUT_TEXT_COLOR};
  border-radius: 4px;
  font-size: 14px;
`;

export const StyledTagRemove = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  color: inherit;

  svg {
    width: 14px;
    height: 14px;
  }

  &:hover {
    opacity: 0.7;
  }
`;

export const StyledOption = styled.div<{
  selected?: boolean;
  disabled?: boolean;
}>`
  padding: 12px 16px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  color: #000;
  transition: background-color 0.2s ease;

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
    `}

  ${({ selected }) =>
    selected &&
    css`
      background-color: ${FOCUS_INPUT_BACKGROUND_COLOR};
    `}

  &:hover {
    background-color: ${({ disabled }) =>
      disabled ? 'transparent' : FOCUS_INPUT_BACKGROUND_COLOR};
  }
`;

export const StyledCheckboxOption = styled(StyledOption)`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;

  &:hover {
    background-color: transparent;
  }
`;

export const StyledPlaceholder = styled.span<{ $hasValue?: boolean }>`
  color: ${({ $hasValue }) => ($hasValue ? INPUT_TEXT_COLOR : '#6c757d')};
  font-size: 14px;
  font-weight: ${({ $hasValue }) => ($hasValue ? '500' : 'normal')};
`;

export const StyledErrorMessage = styled(StyledErrorMessageInput)`
  margin-top: 4px;
`;