import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import { SelectContainer, StyledSelect  } from './Select.styled.tsx';

interface Option {
  value: string | number;
  label: string;
}

interface SelectProps {
  options: Option[];
  onSelect: (value: string | number) => void;
  placeholder?: string;
  id?: string;
  label?: string
  clearValue: boolean
  value?: string
}


const StyledOption = styled.option``;

const Select: React.FC<SelectProps> = ({ options, onSelect, placeholder = '', id, clearValue, value }) => {
  const [selectValue, setSelectValue] = useState(value || '');

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    onSelect(event.target.value);
    setSelectValue(event.target.value)
  };

  useEffect(() => {
    if (clearValue) {
      setSelectValue(''); 
    }
  }, [clearValue]);


  return (
    <SelectContainer>
      <StyledSelect value={selectValue} onChange={handleChange} id={id}>
        {placeholder && <StyledOption value=''>{placeholder}</StyledOption>}
        {options?.map((option) => (
          <StyledOption key={option.value} value={option.value}>
            {option.label}
          </StyledOption>
        ))}
      </StyledSelect>
    </SelectContainer>
  );
};

export default Select;