import { useEffect, useState, useRef, useCallback } from "react";
import { apiRoutes, apiRequest } from "../../../services";
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement
} from 'chart.js';
import './DashboardTeam.css';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import ModalAllAlertsTeam from './ModalAllAlertsTeam.js'
import DashboardAlertSkeleton from "../../../components/Skeletons/DashboardAlertSkeleton/DashboardAlertSkeleton";
import { getDescendingColors, getAscendingColors } from '../../../utils/colors';
import Spinner from '../../../components/Spinner/Spinner'
import MultiCheckbox from "../../../components/MultiCheckbox";
import SummaryTable from '../../../components/SummaryTable'
import MetricCard from '../../../components/MetricCard'
import ContainerCard from '../../../components/ContainerCard'
import TitleApp from '../../../components/TitleApp'
import SubTitleApp from '../../../components/SubTitleApp'
import { MONTH_ORDER } from "../../../utils/constants.js";
import Alert from "../../../components/Alert/Alert.js";
import moment from "moment"



ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);


export default function DashboardTeam({ searchedSelectedAlert, toggleCheckboxEntitiesTeams }) {
  const [loading, setLoading] = useState({ chartsTeams: true });
  const [error, setError] = useState({ chartsTeams: false });
  const [selectedBetterWorse, setSelectedBetterWorse] = useState('desc');
  const [teamSelectionMode, setTeamSelectionMode] = useState('teams');
  const [dataBetterWorseAlerts, setDataBetterWorseAlerts] = useState([]);
  const [showModalMoreAlert, setShowModalMoreAlert] = useState(false);
  const [selectedAlert, setSelectedAlert] = useState({});
  const [colorCharts, setColorCharts] = useState([]);
  const [notDataBetterWorse, setNotDataBetterWorse] = useState(false);
  const [isLoadingBetterWorse, setIsLoadingBetterWorse] = useState(false);
  const [teamSelection, setTeamSelection] = useState('');
  const [dataMonthTeam, setDataMonthTeam] = useState();
  const [isLoadingMonthTeam, setIsLoadingMonthTeam] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState('');
  const [lastMonth, setLastMonth] = useState('');
  const [allTeamBySelect, setAllTeamBySelect] = useState([]);
  const [loadingAllTeamBySelect, setLoadingAllTeamBySelect] = useState(false);
  const [showAlertMostRecentMonth, setShowAlertMostRecentMonth] = useState(false);
  const [filterEntitiesTeams, setFilterEntitiesTeams] = useState([]);
  const [isOpenDropdownEntityTeams, setIsOpenDropdownEntityTeams] = useState(false);
  const [searchEntityTeam, setSearchEntityTeams] = useState('');
  const [showAlertMostRecentMonthBar, setShowAlertMostRecentMonthBar] = useState(false);
  const [groupDataByEntityAndTeam, setGroupDataByEntityAndTeam] = useState({});
  const [isLoadingComparisonOfAllTeams, setIsLoadingComparisonOfAllTeams] = useState(false);
  const [dataComparisonOfAllTeams, setDataComparisonOfAllTeams] = useState({});

  const dropdownEntityTeamsRef = useRef(null);
  

  useEffect(() => {
    if (teamSelection !== '') {
      getMonthTeam()
    }
  }, [teamSelection]);

  useEffect(() => {
    geBetterWorse();
    setColorCharts(
      selectedBetterWorse === 'desc'
        ? getDescendingColors()
        : getAscendingColors()
    );
  }, [selectedBetterWorse, teamSelectionMode]);

  useEffect(() => {
    getEntityTeams()
  }, []);

  const closeDropdowns = useCallback((event) => {
    const dropdowns = [
      { ref: dropdownEntityTeamsRef, setter: setIsOpenDropdownEntityTeams }
    ];

    dropdowns.forEach(({ ref, setter }) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setter(false);
      }
    });
  }, []);

  useEffect(() => {
    document.addEventListener('click', closeDropdowns);
    return () => document.removeEventListener('click', closeDropdowns);
  }, [closeDropdowns]);

  useEffect(() => {
    if (filterEntitiesTeams.length > 0 ) {
      setTeamSelection(filterEntitiesTeams[0])
      const teams= filterEntitiesTeams.map(teamId => 
        allTeamBySelect.find(id => id.entity_id === teamId)
      );
      filterEntitiesTeams.length > 1 && getComparisonOfAllTeams(teams)
    } 
  }, [filterEntitiesTeams]);

  useEffect(() => {
      const filteredData = Object.entries(dataComparisonOfAllTeams).length > 0 && dataComparisonOfAllTeams?.map(team => ({
        ...team,
        data: team.data?.filter(item => item?.month === selectedMonth)
    }))

    if (Object.entries(filteredData).length > 0) {
      const groupedData = groupDataByTeamAndEntity(filteredData);
      setGroupDataByEntityAndTeam(groupedData)
    }

    }, [selectedMonth, dataComparisonOfAllTeams]);


  const geBetterWorse = async () => {
    setNotDataBetterWorse(false);
    setIsLoadingBetterWorse(true);

    try {
      const [error, data] = await apiRequest({
        method: "get",
        url: `${apiRoutes.geBetterWorse}?entity=team&limit=10&query_order=${selectedBetterWorse}&filter_mode=${teamSelectionMode === 'all' ? 0 : 1}`,
      });

      if (!error) {
        if (data?.alerts_summary?.length === 0 || data?.status === 204) {
          if (teamSelectionMode === 'teams') {
            setTeamSelectionMode('all');
          } else {
            setNotDataBetterWorse(true);
          }
        } else {
          setDataBetterWorseAlerts(data.alerts_summary);
          setNotDataBetterWorse(false);
        }
      } else {
        setError(prev => ({ ...prev, chartsTeams: true }));
      }
    } catch (error) {
      console.error(error);
      setError(prev => ({ ...prev, chartsTeams: true }));
    } finally {
      setIsLoadingBetterWorse(false);
      setLoading(prev => ({ ...prev, chartsTeams: false }));
    }
  };

  const getRelatedId = () => {
    const findTeamSelected = allTeamBySelect.find(team => team.entity_id === teamSelection)
    return [...findTeamSelected?.relations_data, teamSelection]?.flat()
  }

  const getMonthTeam = async () => {
    setIsLoadingMonthTeam(true)
    try {
      const relatedIds = allTeamBySelect.find(team =>  team.entity_id ===  teamSelection)?.relations_data
      const [error, data] = await apiRequest({
        method: "get",
        url: `${apiRoutes.getMonthTeam}?id=${teamSelection}&related-ids=${relatedIds}`,
      });

      if (!error) {
        const filteredData = data?.filter(item => item.field_commercial_name !== null);
        const uniqueMonths = [...new Set(filteredData.map(item => item.month))]
          .sort((a, b) => MONTH_ORDER[a] - MONTH_ORDER[b])
          .slice(-3);
        const uniqueTeams = [...new Set(filteredData.map(item => item.team_id))];
        const uniqueEntities = [...new Set(filteredData.map(item => item.entity_label))];

        const groupedData = filteredData?.reduce((acc, item) => {
          const { month, entity_label } = item;

          if (!acc[month]) {
            acc[month] = {};
          }
          if (!acc[month][entity_label]) {
            acc[month][entity_label] = [];
          }
          acc[month][entity_label].push(item);

          return acc;
        }, {});

        const colors = [
          '#ff872e',
          '#8dcfa1',
          '#b37bb9',
          '#f7c604',
          '#329CAD'
        ]

        const datasets = uniqueEntities.map((entity, key) => {
          return {
            label: entity,
            data: uniqueMonths.map(month => {
              const monthData = groupedData && groupedData[month] && groupedData[month][entity] ? groupedData[month][entity] : [];
              return monthData.reduce((sum, item) => sum + item.count, 0);
            }),
            fill: false,
            backgroundColor: colors[key],
            tension: 0.1
          };
        });

        setDataMonthTeam({
          data: groupedData,
          months: uniqueMonths,
          teams: uniqueTeams,
          graph: {
            labels: uniqueMonths,
            datasets
          }
        })

        const lastMonth = uniqueMonths[uniqueMonths.length - 1];
        if (groupedData && groupedData[lastMonth]) {
          setSelectedMonth(lastMonth)
          setLastMonth(lastMonth)
        } else {
          setSelectedMonth(uniqueMonths[uniqueMonths.length - 2])
          setLastMonth(uniqueMonths[uniqueMonths.length - 2])
        }
      }
    } catch (error) {
      console.log("Error", error);
    } finally {
      setIsLoadingMonthTeam(false)
    }
  };

  const getEntityTeams = async () => {
    setLoadingAllTeamBySelect(true)
    try {
      const [error, data] = await apiRequest({
        method: "get",
        url: `${apiRoutes.filterEntity}?pd_entities=Teams`,
      });
  
      if(!error) {
        setAllTeamBySelect(data?.data?.entities)
      }
    } catch (error) {
        console.log("ERROR",error );
    }  finally { 
      setLoadingAllTeamBySelect(false)
    }
  }

  const getComparisonOfAllTeams = async (teams) => {
    setIsLoadingComparisonOfAllTeams(true)
    const dataTeams = teams?.map(team=> {
      return {
          "id" : team.entity_id,
          "related_ids": team.relations_data
      }
    })
    try {
      const [error, data] = await apiRequest({
        method: "post",
        url: apiRoutes.getMonthTeam,
        data: {
          "teams" : dataTeams
      },
      });
  
      if(!error) {
        setDataComparisonOfAllTeams(data)
      }
    } catch (error) {
        console.log("ERROR",error );
    }  finally { 
      setIsLoadingComparisonOfAllTeams(false)
    }
  }

  const groupDataByTeamAndEntity = (data) => {
    return data.reduce((result, teamData) => {
      const teamId = teamData.id;

      if (!result[teamId]) {
        result[teamId] = {
          escalation_policy: 0,
          schedule: 0,
          service: 0,
          team: 0,
          user: 0,
        };
      }

      teamData.data.forEach((entry) => {
        const entity = entry.entity;
        if (result[teamId][entity] !== undefined) {
          result[teamId][entity] += entry.count;
        }
      });
      return result;
    }, {});
  };

  const tableComponent = (groupDataByEntityAndTeam ) => {
    const categoryMap = {
      'Escalation Policy': 'escalation_policy',
      'Schedules': 'schedule',
      'Services': 'service',
      'Teams': 'team',
      'Users': 'user',
    };

    const categories = Object.keys(categoryMap)
  
    const teamNames = Object.keys(groupDataByEntityAndTeam);
    const teamNamesSelect = teamNames.map((team, index) => 
      allTeamBySelect.find(id => id.entity_id === team)
    )

    const headers = ['', ...teamNamesSelect.map((team) => team?.entity_name || 'Unknown Team')];

    const data = categories.map((category) => ({
      objectType: category,
      severityLevels: teamNames.map((team) => groupDataByEntityAndTeam[team][categoryMap[category]] || 0),
    }));

    return (
      <SummaryTable data={data} headers={headers} />
    );
  };
  

  const onClickViewMore = (alert) => {
    setShowModalMoreAlert(true)
    setSelectedAlert(alert)
  }

  const handleMonthChange = (e) => {
    setSelectedMonth(e.target.value);
  };


  const clickCardCheckAlertTrends = (alertName, entity) => {
    if (lastMonth !== selectedMonth) {
      setShowAlertMostRecentMonth(true)
    } else {
      const idsAllAlerts = getRelatedId()
      setShowAlertMostRecentMonth(false)
      searchedSelectedAlert(alertName)
      if (entity === 'Teams') {
        toggleCheckboxEntitiesTeams(teamSelection)
      } else {
        toggleCheckboxEntitiesTeams(idsAllAlerts)
      }
    }
  }

  const filteredOptions = (options, searchState, field) => {
    return options?.filter(option =>
      (field ? option[field] : option)?.toLowerCase().includes(searchState?.toLowerCase()) 
    );
  };

  const toggleCheckboxAllTeams  = (entities) => { 
    toggleCheckboxAll(entities, setFilterEntitiesTeams)
  }

  const toggleCheckboxAll = async (item, setState) => {
    setState(prevState => {
      const newState = [...prevState];
      const itemIndex = newState.indexOf(item);

      if (itemIndex === -1) {
        newState.push(item);
      } else {
        newState?.splice(itemIndex, 1);
      }
      return newState;
    });
  };

  const handleBarClick = (event, elements, chart) => {
    if (elements.length > 0) {
      const element = elements[0];
      const index = element.index;
      const datasetIndex = element.datasetIndex;
      const yValueMonth = chart.data.labels[index];
      
      if (lastMonth !== yValueMonth) {
        setShowAlertMostRecentMonthBar(true);
      } else {
        handleDatasetClick(chart.data.datasets[datasetIndex].label);
      }
    }
  };

  const handleDatasetClick = (datasetLabel) => {
    const team = selectedTeam.find(team => team.entity_id === teamSelection);
    const datasetMapping = {
      'Users': 'users',
      'Escalation Policy': 'escalation_policies',
      'Services': 'services',
      'Teams':'teams',
      'Schedules':'schedules'
    };


    const propertyName = datasetMapping[datasetLabel];
    let resultRelationsId = [];

    if (datasetLabel === 'Users') {
      resultRelationsId = team.raw_relations_data[propertyName].map(item => item.id);
    } else {
      resultRelationsId = team.raw_relations_data[propertyName];
    }

    if (datasetLabel === 'Teams') {
      resultRelationsId = team?.entity_id
    };
    toggleCheckboxEntitiesTeams(resultRelationsId);
    setShowAlertMostRecentMonthBar(false);
  };



  const handleDropdownEntityTeamsClick = () => setIsOpenDropdownEntityTeams(!isOpenDropdownEntityTeams)

  const filteredOptionsTypeEntityTeams = filteredOptions(allTeamBySelect, searchEntityTeam, 'entity_name')
  const filteredEntities = dataMonthTeam && dataMonthTeam?.data[selectedMonth] || {};
  const selectedTeam = filterEntitiesTeams.map(teamId => 
    allTeamBySelect.find(id => id.entity_id === teamId)
  );

  const dataBarTeams = {
    labels: dataBetterWorseAlerts?.map(item => item.entity),
    datasets: [{
      axis: 'y',
      label: '',
      data: dataBetterWorseAlerts?.map(item => item.punctuation),
      fill: false,
      backgroundColor: colorCharts,
      borderWidth: 1
    }]
  };

  /**
   * Returns an array containing the names of the last three months relative to the current month.
   * @function getLastThreeMonths
   * @returns {string[]} An array of the last three month names in order from most recent to oldest
   * @example
   * // If current month is January
   * getLastThreeMonths(); // Returns ["December", "November", "October"]
   * 
   * // If current month is July
   * getLastThreeMonths(); // Returns ["June", "May", "April"]
   */
  function getLastThreeMonths() {
    const currentMonth = moment();
    const months = [];

    for (let i = 1; i <= 3; i++) {
      months.push(
        currentMonth.clone().subtract(i, "months").format("MMMM"),
      );
    }

    return months;
  }

  return (
    <ContainerCard>
      {loading.chartsTeams && <div ><DashboardAlertSkeleton /></div>}
      {error.chartsTeams &&
        <div className="graphics try__againTemAlerts">
          <div>Oops, it looks like we weren’t able to find your The best and worst alerts information. We kindly request you to try Again later</div>
          <button className="button__try" onClick={() => {
            geBetterWorse()
            setLoading(prev => ({ ...prev, chartsTeams: true }));
            setError(prev => ({ ...prev, chartsTeams: false }));
          }}>Try Again</button>
        </div>}
      <div className="">
        {!loading.chartsTeams && !error.chartsTeams &&
          <div className="">
            <TitleApp>Team Health Score</TitleApp>
            <div className="text__teamSelectionMode">Select My Teams to see the health score of your teams. Select All Teams to see the Top 10 Best or Worse.</div>
            <div className="container__betterWorse">
              <div>
                <SubTitleApp>By Team</SubTitleApp>
                <select
                  onChange={(e) => { setTeamSelectionMode(e.target.value) }}
                  className="select__teams"
                  value={teamSelectionMode}
                >
                  <option key={'all'} value={'all'}>Top 10 Teams</option>
                  <option key={'teams'} value={'teams'}>My Teams</option>
                </select>
              </div>
              <div>
                <SubTitleApp>By Health</SubTitleApp>
                <select
                  onChange={(e) => setSelectedBetterWorse(e.target.value)}
                  className="select__teams"
                  value={selectedBetterWorse}
                >
                  <option key={'asc'} value={'asc'}>Better (healthiest)</option>
                  <option key={'desc'} value={'desc'}>Worst (unhealthiest)</option>
                </select>
              </div>
            </div>
            {isLoadingBetterWorse && <Spinner />}
            {!isLoadingBetterWorse && !notDataBetterWorse && <div style={{ width: '100%', marginTop: '10px', height: '400px', marginBottom: '50px' }} >
              <Bar 
              data={dataBarTeams} 
              options={{
                indexAxis: 'y',
                scales: {
                  x: {
                    beginAtZero: true,
                    grid: {
                      display: false
                    },
                  },
                  y: {
                    position: 'left',
                    ticks: {
                      autoSkip: false,
                      maxRotation: 0,
                      minRotation: 0,
                      padding: 10,
                      align: 'start', 
                      font: {
                        size: 16, 
                        weight: '400',
                        lineHeight: 1.5,
                      },
                      color: 'rgba(52, 52, 52, 1)', 
                    },
                    grid: {
                      display: false
                    }
                  }
                },
                plugins: {
                  legend: {
                    position: 'top',
                    align: 'center',
                    labels: {
                      boxWidth: 0,
                      font: {
                        size: 15,
                        weight: 'bold'
                      },
                      color: '#000',
                    },
                  },
                  tooltip: {
                    enabled: false,
                  },
                  datalabels: {
                    color: selectedBetterWorse === 'desc' ? '#fff' : '#000',
                    textAlign: 'start',
                    font: {
                      size: 14,
                      weight: 'bold'
                    },
                  },
                },
                elements: {
                  bar: {
                    barPercentage: 0.8,
                    categoryPercentage: 0.8,
                    barThickness: 'flex',
                    maxBarThickness: 20
                  }
                },
                maintainAspectRatio: false,
                responsive: true,
                onHover: (event, elements) => {
                  if (elements.length > 0) {
                    event.native.target.style.cursor = 'pointer'; 
                  } else {
                    event.native.target.style.cursor = 'default'; 
                  }
                },
                onClick: (event, elements, chart) => {
                  if (elements.length > 0) {
                    const index = elements[0].index;
                    const yValue = chart.data.labels[index];
                    const selectTeam = allTeamBySelect.find(team => team.entity_name === yValue )
                    toggleCheckboxEntitiesTeams(selectTeam.entity_id)
            
                  }
                },
              }}
              height={"200px"} />
            </div>}
            {notDataBetterWorse && !isLoadingBetterWorse && <div style={{ marginTop: '20px', textAlign: 'center' }}>We could not find the requested data.</div>}
            {/* {!isLoadingBetterWorse && !notDataBetterWorse && <div className="separator"></div>} */}
            {!isLoadingBetterWorse && !notDataBetterWorse && <TitleApp>Team Health Score Summary</TitleApp>}
            {!isLoadingBetterWorse && !notDataBetterWorse &&
              <div className="text__description">
                For each of the teams above, review the team’s Health Check Alerts. The Health Check score is the sum of each alert’s weight, with the higher score indicating a greater need for attention to that team’s overall health.
              </div>
            }
            <div className="teams__container">
              {!isLoadingBetterWorse && dataBetterWorseAlerts?.map((item, index) => (
                <div key={index} className="team-column">
                  <div className="title__nameTeamDashboard">{item.entity}</div>
                  {item?.alerts?.slice(0, 3)?.map((alert, alertIndex) => (
                    <div key={alertIndex} className="numeric__impactTitle">
                        <div className="numeric__impact">{alert.numeric_impact}</div>
                        <div  className={`link__typeBetterWorse ${alertIndex !== item?.alerts?.slice(0, 3).length - 1 ? 'border__bottom' : ''}`} >{alert.title}
                        </div>
                    </div>
                  ))}
                  <div className="container__scoreCircleDashboardTeam">
                    <div className="punctuation__footerDashboardTeam">
                      <div>Team Score:</div>
                      <div className="score__alertsTeams">{item.punctuation}</div>
                    </div>
                    <div onClick={() => onClickViewMore(item)} className="view__moreTeam" >View more</div>
                  </div>
                </div>
              ))}
            </div>
          </div>}
      </div>
      <div className="container__alertsSummaryEntitySeverity">
        <div className="container__monthlyHealth">
          {!loadingAllTeamBySelect && 
            <TitleApp>Monthly Health Check Alert Trends</TitleApp>
          }
          {loadingAllTeamBySelect && <DashboardAlertSkeleton />}

          {!loadingAllTeamBySelect && allTeamBySelect?.length > 0 &&
            <div className="container__selectTeam">
              <div className="select__teamDashboardTeam">
                  <MultiCheckbox
                  label="Team Name"
                  options={allTeamBySelect}
                  filterState={filterEntitiesTeams}
                  setFilterState={setFilterEntitiesTeams}
                  isOpenDropdown={isOpenDropdownEntityTeams}
                  searchState={searchEntityTeam}
                  setSearchState={setSearchEntityTeams}
                  filteredOptions={filteredOptionsTypeEntityTeams}
                  handleDropdownClick={handleDropdownEntityTeamsClick}
                  toggleCheckbox={toggleCheckboxAllTeams}
                  mapFunction={(item) => item?.entity_id}
                  nameLabel={(item) => item?.entity_name}
                  dropdownRef={dropdownEntityTeamsRef}
                  disabled={!filteredOptionsTypeEntityTeams.length > 0}
                  disabledItem={filterEntitiesTeams.length >= 5}
                  width={'40%'}
                  />
                  <div className="container__selectMonth">
                    <select
                    className="select__teams month__select"
                    onChange={handleMonthChange}
                    value={selectedMonth}>
                    {(dataMonthTeam?.months ? getLastThreeMonths() : []).map((month) => (
                      <option key={month} value={month}>
                        {month}
                      </option>
                    ))}
                    </select>
                  </div>
              </div>
              <div>
              </div>
            </div>}
          {!loadingAllTeamBySelect && 
            filterEntitiesTeams.length > 1 && <div className="table__comparison">
              <TitleApp>Teams Comparison</TitleApp>
              {Object.keys(groupDataByEntityAndTeam).length > 0 && !isLoadingComparisonOfAllTeams && tableComponent(groupDataByEntityAndTeam)}
              {isLoadingComparisonOfAllTeams && <Spinner />}
            </div>}
          {!loadingAllTeamBySelect && (!dataMonthTeam ||  Object.keys(dataMonthTeam?.data).length === 0 ) &&
            <div className="centered-message">Unfortunately, we do not have enough data to display the graph.</div>
          }
          {showAlertMostRecentMonth && <Alert message="Clicking to drill down into filtered Health Check Alerts view is only available for the current month." />}
          {isLoadingMonthTeam && !loadingAllTeamBySelect &&  <Spinner />}
          {!isLoadingMonthTeam &&  filterEntitiesTeams?.length > 0 &&
            <div className="container__teamsSelected">
              {selectedTeam.map(team => (
                <div 
                key={team.entity_id}
                className={teamSelection === team.entity_id ? 'selectSession__button' : 'disabled__selectSession '}
                onClick={() => {
                  setTeamSelection(team.entity_id) 
                  setShowAlertMostRecentMonthBar(false)
                }} 
                >{team.entity_name}</div>
              ))}
            </div>
          }
        {!isLoadingMonthTeam  && filterEntitiesTeams?.length > 0 &&
          <div className="container__infoAlertsByMonth">
              {!isLoadingMonthTeam && !isLoadingBetterWorse && 
                  Object.entries(filteredEntities)?.map(([entity, items]) => (
                    <div className="container__teamByMonth" key={entity}>
                      <SubTitleApp>{entity}</SubTitleApp>
                      <div className="container__alertByTeamMonth">
                        {items.map((alert, index) => (
                          <MetricCard
                            title={alert?.field_commercial_name}
                            value={alert?.count}
                            onClick={() => clickCardCheckAlertTrends(alert.field_commercial_name, entity)}
                            text={"ALERTS"}
                            />
                        ))}
                      </div>
                    </div>
                  ))
              }
            <div>
            </div>
              {!isLoadingMonthTeam && !isLoadingBetterWorse && 
              <div className="container__barMonth">
                {showAlertMostRecentMonthBar && <Alert message="Clicking to drill down into filtered Health Check Alerts view is only available for the current month." />}
                  {dataMonthTeam?.graph && dataMonthTeam?.graph.datasets.length > 0 && (
                    <Bar
                      data={dataMonthTeam?.graph}
                      options={
                        {
                        onClick: handleBarClick,
                        scales: {
                          y: {
                            beginAtZero: true,
                            title: {
                              display: true,
                              text: 'Alerts',
                            },
                          },
                          x: {
                            title: {
                              display: true,
                              text: 'Months',
                            },
                          },
                        },
                        onHover: (event, elements) => {
                          if (elements.length > 0) {
                            event.native.target.style.cursor = 'pointer'; 
                          } else {
                            event.native.target.style.cursor = 'default'; 
                          }
                        },
                        plugins: {
                          datalabels: {
                            color: '#fff',
                            font: {
                              weight: 'bold',
                            },
                          },
                        },
                      }}
                    />
                  )}
              </div>}
          </div>}
        </div>
      </div>
      {showModalMoreAlert &&
        <ModalAllAlertsTeam
          setShowModalMoreAlert={setShowModalMoreAlert}
          selectedAlert={selectedAlert}
          searchedSelectedAlert={searchedSelectedAlert}
        />
      }
    </ContainerCard>
  );
}
