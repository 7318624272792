import styled from 'styled-components';

export const SelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const StyledSelect = styled.select`
    outline: none;
    width: 100%;
    font-family: Plain-Regular, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    color: rgb(51, 51, 51);
    background-color: rgb(255, 255, 255);
    border-radius: 8px;
    border: 1px solid rgb(224, 224, 224);
    transition: border-color 0.2s ease-in-out;
    min-height: 55px;
    padding: 8px 16px;
    cursor: pointer;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
    gap: 8px;
    &:focus {
      border-color: rgb(169 165 165);
    }
`;
