import React, { useState, useEffect, useRef } from "react";
import GenericTable from "../../components/GenericTable/GenericTable";


const UsersSummaryTable = ({ 
  projects,
  totalProjects,
  currentPage,
  setCurrentPage,
  itemsPerPage,
  setItemsPerPage}) => {
  const tableRef = useRef(null);


  const handleSearch = (searchValue) => {
    console.log("Search value:", searchValue);
  };

  
  const titles = [
    { label: "User Name", widthPX: 200, rowKey: "member_name", type: "String" },
    { label: "User Email", widthPX: 250, rowKey: "member_email", type: "String" },
    { label: "User Registered Courses", widthPX: 240, rowKey: "member_registered_courses", type: "String" },
    { label: "User Completed Courses", filtrable: true, widthPX: 240, rowKey: "member_completed_courses", type: "String" },
    { label: "User Certificates", filtrable: true, widthPX: 200, rowKey: "member_certificates", type: "String" },

  ];

  return (
    <div className="premium-request-table">
      <GenericTable
        ref={tableRef}
        title=""
        columnsArray={titles}
        rowsArray={projects}
        onActionClick={(action, row) => console.log("Action Clicked:", action, row)}
        showElementsPerPage={itemsPerPage}
        elementOnClick={(row) => console.log("Row clicked:", row)}
        isLoading={false}
        filterDateKey="task_created"
        onSearchChange={handleSearch}
        onPaginationChange={setCurrentPage}
        actualPage={currentPage}
        setActualPage={setCurrentPage}
        itemsPerPage={itemsPerPage}
        setItemsPerPage={setItemsPerPage}
        totalItems={totalProjects}
      />
    </div>
  );
};

export default UsersSummaryTable;