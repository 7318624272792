import styled, { css } from 'styled-components';

export const INPUT_BACKGROUND_COLOR = '#fff';
export const INPUT_TEXT_COLOR = '#333';
export const INPUT_BORDER_COLOR = '#e0e0e0';
export const ERROR_MESSAGE_INPUT_COLOR = '#ff253a';
export const ERROR_MESSAGE_INPUT_BACKGROUND_COLOR = 'rgba(255, 37, 58, 0.1)';
export const FOCUS_INPUT_COLOR = 'rgb(169 165 165)';
export const FOCUS_INPUT_BACKGROUND_COLOR = 'rgba(0, 123, 255, 0.1)';
export const DISABLED_INPUT_COLOR = '#f8f9fa';

export const StyledInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
`;

export const StyledInputLabel = styled.label<{ required?: boolean }>`
  font-family: 'Plain-Regular', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 14px;
  color: #333;
  margin-bottom: 4px;

  ${({ required }) =>
    required &&
    css`
      &::after {
        content: '*';
        color: ${ERROR_MESSAGE_INPUT_COLOR};
        margin-left: 4px;
      }
    `}
`;

export const StyledErrorMessageInput = styled.span`
  color: ${ERROR_MESSAGE_INPUT_COLOR};
  font-family: 'Plain-Regular', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 12px;
  margin-top: 4px;
`;

export const IconWrapper = styled.div<{ position: 'start' | 'end' }>`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  ${({ position }) => (position === 'start' ? 'left: 16px;' : 'right: 16px;')}
  display: flex;
  align-items: center;
  color: #6c757d;

  svg {
    width: 20px;
    height: 20px;
  }
`;

export const StyledPasswordToggle = styled.button`
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  color: #6c757d;
  display: flex;
  align-items: center;
  padding: 0;

  &:hover {
    color: #333;
  }

  svg {
    width: 20px;
    height: 20px;
  }
`;

export const baseInputStyles = css<{ hasError?: boolean }>`
  outline: none;
  padding: 16px;
  width: 100%;
  font-family: 'Plain-Regular', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  color: ${INPUT_TEXT_COLOR};
  background-color: ${INPUT_BACKGROUND_COLOR};
  border-radius: 8px;
  border: 1px solid
    ${({ hasError }) =>
      hasError ? ERROR_MESSAGE_INPUT_COLOR : INPUT_BORDER_COLOR};
  transition: border-color 0.2s ease-in-out;

  &:focus {
    border-color: ${({ hasError }) =>
      hasError ? ERROR_MESSAGE_INPUT_COLOR : FOCUS_INPUT_COLOR};
    box-shadow: 0 0 0 2px
      ${({ hasError }) =>
        hasError
          ? ERROR_MESSAGE_INPUT_BACKGROUND_COLOR
          : FOCUS_INPUT_BACKGROUND_COLOR};
  }

  &:disabled {
    background-color: ${DISABLED_INPUT_COLOR};
    cursor: not-allowed;
  }
`;

export const StyledInput = styled.input<{
  hasError?: boolean;
  hasStartIcon?: boolean;
  hasEndIcon?: boolean;
}>`
  ${baseInputStyles}
  height: 55px;
  padding-left: ${({ hasStartIcon }) => (hasStartIcon ? '48px' : '16px')};
  padding-right: ${({ hasEndIcon }) => (hasEndIcon ? '48px' : '16px')};
`;