import styled from 'styled-components';

export const SearchContainer = styled.div`
  display: flex;
  width:100%;
  gap: 16px;
`;

export const Button = styled.button`
  padding: 8px;
  border-radius: 8px;
  background: #048A24;
  border: none;
  color: #FFFFFF;
  cursor: pointer;

  &:hover {
    background-color: rgb(4 59 17);
  }
  &:disabled {
    opacity: 0.4; 
  }
`;
export const FilterWrapper = styled.div<{ fullWidth: boolean }>`
  flex: 1 1 50%;
`;
export const ContainerButton = styled.div`
  display: flex;
  width: 100%;
  gap: 16px;
  margin-top: 5px;
  align-items: center;
  justify-content: end;
`;
