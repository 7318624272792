import React from 'react';
import { forwardRef, useImperativeHandle } from 'react';
import { Alert, AlertsType } from './Alerts.model.ts';
import {
  AlertContainer,
  CloseIconContainer,
  Container,
  IconContainer,
  LeftContainer,
  Text,
  TextContainer,
  Title,
} from './Alerts.styled.tsx';
import Icon from '../Icon/Icon.tsx';

const Alerts = forwardRef(
  ({ alerts, onRemove, displayCloseButton = true }: AlertsType, ref) => {
    useImperativeHandle(ref, () => ({}));
    return (
      <Container>
        {alerts?.map((alert: Alert, index: number) => (
          <AlertContainer data-testid={`alert-type-${alert.type}`} $type={alert.type} key={alert.text}>
            <LeftContainer>
              <IconContainer $type={alert.type}>
                <Icon icon='ExclamationCircleIcon' type='outline' />
              </IconContainer>
              <TextContainer>
                {alert.title && <Title>{alert.title}</Title>}
                {alert.text && <Text>{alert.text}</Text>}
              </TextContainer>
            </LeftContainer>
            {displayCloseButton && (
              <CloseIconContainer onClick={() => onRemove(index)}>
                <Icon icon='XMarkIcon' type='outline' />
              </CloseIconContainer>
            )}
          </AlertContainer>
        ))}
      </Container>
    );
  }
);

Alerts.displayName = 'Alerts';

export default Alerts;
